// header part

/*******************
 Topbar
*******************/

.topbar {
    position: relative;
    .navbar-collapse {
        padding: 0px;
    }
    .top-navbar {
        min-height: 50px;
        padding: 0px;
        .dropdown-toggle::after {
            display: none;
        }
        .navbar-header {
            background: rgba(0,0,0,0.05);
            line-height: 65px; 
            padding-left: 10px;
            min-width: 70px;
            .navbar-brand {
                margin-right: 0px;
                padding-bottom: 0px;
                padding-top: 0px;
                .light-logo {
                    display: none;
                }
                b {
                    line-height: 50px;
                    display: inline-block;
                }
            }
        }
        
        .profile-pic {
            white-space: nowrap;
            img{
                width: 30px;
                border-radius: 100%;
            }
            span{
                font-size: 14px;
                white-space: nowrap;
            }
        }
        .navbar-nav>.nav-item>.nav-link {
            padding-left: 15px;
            padding-right: 15px;
            font-size: 18px;
            line-height: 50px;
        }
        .navbar-nav>.nav-item>span {
            line-height: 45px;
            font-size: 24px;
            font-weight: 500;
            color: $white;
            padding: 0 10px;
        }
        
    }
    
    .dropdown-menu {
        box-shadow: $shadow;
        -webkit-box-shadow: $shadow;
        -moz-box-shadow: $shadow;
        border-color: $border-color;
        .dropdown-item {
            padding: 7px 1.5rem;
        }
    }
    
}

/*******************
Notify
*******************/

.notify {
    position: relative;
    top: -28px;
    right: -7px;
    .heartbit {
        position: absolute;
        top: -20px;
        right: -4px;
        height: 25px;
        width: 25px;
        z-index: 10;
        border: 5px solid $danger;
        border-radius: 70px;
        -moz-animation: heartbit 1s ease-out;
        -moz-animation-iteration-count: infinite;
        -o-animation: heartbit 1s ease-out;
        -o-animation-iteration-count: infinite;
        -webkit-animation: heartbit 1s ease-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }
    .point {
        width: 6px;
        height: 6px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        background-color: $danger;
        position: absolute;
        right: 6px;
        top: -10px;
    }
}

@-moz-keyframes heartbit {
    0% {
        -moz-transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -moz-transform: scale(0.1);
        opacity: 0.1;
    }
    50% {
        -moz-transform: scale(0.5);
        opacity: 0.3;
    }
    75% {
        -moz-transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        -moz-transform: scale(1);
        opacity: 0.0;
    }
}

@-webkit-keyframes heartbit {
    0% {
        -webkit-transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -webkit-transform: scale(0.1);
        opacity: 0.1;
    }
    50% {
        -webkit-transform: scale(0.5);
        opacity: 0.3;
    }
    75% {
        -webkit-transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0.0;
    }
}

.app-search {
    margin-left: 13px;
    line-height: 60px;  
    .form-control{
        border-radius: 60px;
        border-color: transparent;
        transition: 0.2s ease-in;
        width: 180px;
        &:focus{
            width: 300px;
        }
    }
}

/*******************
Megamenu
******************/

.mega-dropdown {
    position: static;
    width: 100%;
    .dropdown-menu {
        width: 100%;
        padding: 30px;
        margin-top: 0px;
    }
    ul {
        padding: 0px;
        li {
            list-style: none;
        }
    }
    .carousel-item .container {
        padding: 0px;
    }
    .nav-accordion {
        .card {
            margin-bottom: 1px;
        }
        .card-header {
            background: $white;
            h5 {
                margin: 0px;
                a {
                    text-decoration: none;
                    color: $body-color;
                }
            }
        }
    }
}
