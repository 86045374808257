#auth-box {
  background-size: auto;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100%;
  width: 100%;
  padding: 10% 0;
  position: fixed;

  > div {
    width: 400px;
    margin: 0 auto;
  }

  .card-body {
    padding: 50px;
  }
}