/* Bootstrap 4 text input with search icon */

.has-search .form-control {
  padding-left: 2.375rem;
}
.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.has-search .form-control-right {
  display: inline;
  font-size: 1.2em;
  margin-left: -25px;
  color: #aaa;
  cursor: pointer;
}
.pagination .disabled a {
  color: lightgray;
}
/*
Override default form-control
 */
.form-control {
  color: #212529;
  min-height: 38px;
  display: initial;
}
.mega-dropdown {
  position: static;
  width: 100%;
}
.btn-outline-secondary {
  color: #212529;
  background: #fff;
  border-color: #dee2e6;
}
.topbar {
  background: $themecolor; //variables.scss
}
@media (min-width: 767px)
{
  .horizontal-nav .sidebar-nav #sidebarnav > li.active > a {
    border-bottom-width: 3px;
    border-color: $themecolor;
  }
}

/**
Table header
 */
.table thead th {
  vertical-align: middle;
}
/**
Override default cursor
 */
.btn {
  cursor: pointer;
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: darkgrey;
  background-color: transparent;
  cursor: default;
}
/**
Override fontsize on checkboxes
 */
.custom-control.custom-checkbox {
  font-weight: normal;
  font-size: 1.15em;
}
.custom-control.custom-checkbox > label {
  cursor: pointer;
}
/** Bootstrap' "invisible" sets visibility to hidden */
.st-hide {
  display: none !important;
}